import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom"

function Page(props) {

    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (props.title) document.title = `${props.title} | Costopiatto`;
        else document.title = `Costopiatto`;
    }, [props.title])

    // console.log({ pathname });

    useEffect(() => {
        if (hash === '') {
            window.scrollTo({
                behavior: "smooth",
                top: 0
            });
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                window.scrollTo({
                    behavior: "smooth",
                    top: element ? element.offsetTop - 90 : 0
                });
            }, 0);
        }
    }, [pathname, hash]);

    return (
        <>
            <div className="content">

                {props.children}

            </div>
        </>
    );
}

export default Page;
