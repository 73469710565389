import http from "./httpService";
import qs from 'querystring'
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function getData({ token, request }) {
    return await http({
        method: 'get',
        headers: { 'authorization': token },
        url: `${config.api}/getData`,
        cancelToken: request.token
    });
}

export async function register({ email, password, currency, language, request }) {
    return await http({
        method: 'post',
        url: `${config.api}/register`,
        data: qs.stringify({ email, password, currency, language }),
        cancelToken: request.token
    });
}

export async function login({ email, password, request }) {
    return await http({
        method: 'post',
        url: `${config.api}/login`,
        data: qs.stringify({ email, password }),
        cancelToken: request.token
    });
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    getData,
    cancelToken,
    register,
    login
};
