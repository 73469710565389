import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import ImageFadeIn from "react-image-fade-in";
import _ from "lodash";
import { t } from '../lang';
import sprintfReact from '../utils/sprintfReact';
import defaultError from '../utils/defaultError';
import checkNested from '../utils/checkNested';
import validateEmail from '../utils/validateEmail';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

import Secret from './Secret';

// services
import auth from "../services/authService";

const images = ['003', '004'];
const image = _.shuffle(images)[0];

function Login() {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState('');

    const lang = appState.lang;

    const handleClose = useCallback(() => {

        appDispatch({ type: 'toggleShowLogin' });

        // eslint-disable-next-line
    }, []);

    function handleSubmit(e) {

        e.preventDefault();

        async function fetchData() {

            const request = auth.cancelToken();

            setIsLoading(true);

            try {

                const response = await auth.login({ email: username.toLowerCase(), password, request });

                // setTimeout(() => {
                setIsLoading(false);
                // }, 500);

                if (!checkNested(response, 'data', 'token')) return appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: t(lang, 'alerts.INVALID_CREDENTIALS'),
                });

                appDispatch({ type: "setToken", data: response.data.token });

                const user = {
                    currency: response.data.currency,
                    language: response.data.language,
                    avatar: response.data.avatar
                }

                appDispatch({ type: "setUser", data: user });
                if (checkNested(response, 'data', 'language')) appDispatch({ type: 'setLang', data: response.data.language })
                appDispatch({ type: 'setRedirectTrigger', data: true });

                // setTimeout(() => {
                // }, 500);

                appDispatch({ type: "toggleShowLogin" });

                // <Redirect to={'/'} />
                // window.history.pushState(null, null, '/');

            } catch (e) {

                if (e.isCancel !== true) {
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultError(e)
                    });
                    setIsLoading(false);
                }

            }
        }

        if (!validateEmail(username)) return appDispatch({ type: "alertOpen", ico: "error", value: t(lang, 'alerts.EMAIL_NOT_VALID') });
        if (password.length < 8) return appDispatch({ type: "alertOpen", ico: "error", value: t(lang, 'alerts.PASSWORD_NOT_VALID') });

        fetchData();

    }

    useEffect(() => {

        function searchKeyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        document.addEventListener("keyup", searchKeyPressHandler)
        return () => document.removeEventListener("keyup", searchKeyPressHandler)

    }, [handleClose]);

    return (
        <>
            <button onClick={(e) => handleClose()} className="btn btn--close">.</button>
            <div className="col col--bg">
                <div className="box">
                    <div className="logo"></div>
                    <h3>{t(lang, 'login.title')}</h3>
                    <p>{
                        sprintfReact(
                            t(lang, 'login.disclaimer'),
                            [
                                <Link onClick={(e) => handleClose()} to={"privacy-policy"}>{t(lang, 'footer.privacyPolicy')}</Link>,
                                <Link onClick={(e) => handleClose()} to={"cookie-policy"}>{t(lang, 'footer.cookiePolicy')}</Link>,
                                <Link onClick={(e) => handleClose()} to={"terms-of-use"}>{t(lang, 'footer.termsOfUse')}</Link>
                            ]
                        )
                    }</p>
                </div>
                <div className="bg">
                    <Secret />
                    <ImageFadeIn src={`${process.env.PUBLIC_URL}/assets/img/login/${image}.jpg`} />
                </div>
            </div>
            <div className="col">
                <div className="box">
                    <form onSubmit={handleSubmit}>
                        <div className="input-container"><input type="email" onChange={(e) => setUsername(e.target.value)} placeholder={t(lang, 'login.email')} value={username} /></div>
                        <div className="input-container"><input onChange={(e) => setPassword(e.target.value)} type="password" placeholder={t(lang, 'login.password')} value={password} /></div>
                        <button disabled={isLoading} className="btn" type="submit">
                            <span className="loading"></span>
                            <span>{t(lang, 'login.submit')}</span>
                        </button>
                    </form>
                    <p>{
                        sprintfReact(
                            t(lang, 'login.footer'),
                            [
                                <button onClick={(e) => { appDispatch({ type: 'toggleShowLogin' }); appDispatch({ type: 'toggleShowDemo' }) }}>{t(lang, 'header.requestDemo')}</button>
                            ]
                        )
                    }</p>
                </div>
            </div>
        </>
    );
}

export default Login;
