import React, { useContext } from 'react';
import ImageFadeIn from "react-image-fade-in";
import { t } from '../lang';
import Page from './Page';
import CanvasEffect from './CanvasEffect';

import randomImage from '../utils/randomImage';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

const image = randomImage(['001', '002', '003', '004', '005', '006']);
// const imageTest2 = randomImage(['001', '002', '003', '004', '005', '006']);

function Home() {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const lang = appState.lang;

    return (
        <Page>

            <div className="section section--hero">

                <div className="main">

                    <h2>{t(lang, 'home.hero.title')}</h2>
                    <p>{t(lang, 'home.hero.subtitle')}</p>
                    <button onClick={(e) => appDispatch({ type: 'toggleShowDemo' })} className="btn">{t(lang, 'header.requestDemo')}</button>

                </div>

                <div className="bg">
                    <ImageFadeIn src={`${process.env.PUBLIC_URL}/assets/img/home/${image}.jpg`} />
                </div>

            </div>

            <div id="how-it-works" className="section section--how">

                <h2>{t(lang, 'home.howItWorks.title')}</h2>

                <div className="section section--max-width">

                    {/* <p className="main">{t(lang, 'home.howItWorks.main')}</p> */}

                    <ul>
                        {t(lang, 'home.howItWorks.array').map((el, index) =>
                            <li key={index}>
                                <div className={`ico ico--${el.ico}`}><span></span></div>
                                <h3>{el.title}</h3>
                                <p>{el.text}</p>
                            </li>
                        )}
                    </ul>

                </div>

                <CanvasEffect />

            </div>

            {/* <div id="pricing" className="section section--pricing"></div> */}

            <div id="try" className="section section--try">

                <div className="section section--max-width">

                    <div>
                        <div className="image-container">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/home/try.jpg`} alt="" />
                            <img src={`${process.env.PUBLIC_URL}/assets/img/home/${image}.jpg`} alt="" />
                            {/* <img src={`${process.env.PUBLIC_URL}/assets/img/home/${imageTest2}.jpg`} alt="" /> */}
                        </div>
                    </div>

                    <div>
                        <h4>{t(lang, 'home.try.title')}</h4>
                        <p>{t(lang, 'home.try.text')}</p>

                        <button onClick={(e) => appDispatch({ type: 'toggleShowDemo' })} className="btn">{t(lang, 'header.requestDemo')}</button>

                    </div>

                </div>

                <div className="bg"></div>

            </div>

            <div id="faq" className="section section--faq">

                <h2>{t(lang, 'home.faq.title')}</h2>

                <div className="section section--max-width">

                    <ul>
                        {t(lang, 'home.faq.array').map((el, index) =>
                            <li key={index}>
                                <h3>{el.question}</h3>
                                <p>{el.answer}</p>
                                {el.list && <ul>{el.list.map((li, liIndex) => <li key={liIndex}>{li}</li>)}</ul>}
                            </li>
                        )}
                    </ul>

                </div>

            </div>

        </Page>
    );
}

export default Home;
