import React, { useEffect, useContext, useCallback } from 'react';

// context
// import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

import CanvasEffect from './CanvasEffect';
import Nav from './Nav';

function Login() {

    // const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const handleCloseNav = useCallback(() => {

        appDispatch({ type: 'toggleShowNav' });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        function searchKeyPressHandler(e) {
            if (e.keyCode === 27) {
                handleCloseNav();
            }
        }

        document.addEventListener("keyup", searchKeyPressHandler)
        return () => document.removeEventListener("keyup", searchKeyPressHandler)

    }, [handleCloseNav]);

    return (
        <>
            <button onClick={(e) => handleCloseNav()} className="btn btn--close">.</button>
            <CanvasEffect />
            <div className="box">
                <Nav />
            </div>
        </>
    );
}

export default Login;
