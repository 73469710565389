import React from 'react';

import Page from './Page';
import DotsLoading from './DotsLoading';

function Loading() {
    return (
        <Page>
            <DotsLoading />
        </Page>
    );
}

export default Loading;
