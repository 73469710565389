import checkNested from "./utils/checkNested";
// import ingredientsEn from './lang/en/ingredients.json';
// import ingredientsIt from './lang/it/ingredients.json';

const defaultLang = 'en';

let lang = {
    en: {
        alerts: {
            RECIPE_SAVED: 'Recipe Saved!',
            RECIPE_UPDATED: 'Recipe Updated!',
            RECIPE_DELETED: 'Recipe Deleted!',
            INGREDIENT_SAVED: 'Ingredient Saved!',
            INGREDIENT_UPDATED: 'Ingredient Updated!',
            INGREDIENT_DELETED: 'Ingredient Deleted!',
            PROCESSED_SAVED: 'Processed Saved!',
            PROCESSED_UPDATED: 'Processed Updated!',
            PROCESSED_DELETED: 'Processed Deleted!',
            PLEASE_ENTER_THE_NAME_OF_THE_RECIPE: "Please enter the name of the recipe.",
            PLEASE_INSERT_ALMOST_A_INGREDIENT_OR_PROCESSED: "Please insert almost a ingredient or processed.",
            PLEASE_ENTER_A_VALID_QUANTITY_FOR_THE_INGREDIENTS: "Please enter a valid quantity for the ingredients.",
            PLEASE_ENTER_A_VALID_QUANTITY_FOR_THE_PROCESSED: "Please enter a valid quantity for the processed.",
            ARE_YOU_SURE_YOU_WANT_TO_REMOVE_NAME: `Are you sure you want to remove %1$ ?`,
            ARE_YOU_SURE_YOU_WANT_TO_DUPLICATE_THIS_RECEIPE: `Are you sure you want to duplicate this recipe ?`,
            ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_RECEIPE: `Are you sure you want to remove this recipe ?`,
            THANK_YOU_FOR_YOUR_INTEREST_WE_WILL_REPLY_YOU_SOON: "Thank you for your interest, we will reply you soon.",
            PLEASE_ENTER_ALL_FIELDS: "Please enter all fields.",
            INVALID_CREDENTIALS: "Invalid credentials.",
            EMAIL_NOT_VALID: 'Email not valid.',
            PASSWORD_NOT_VALID: 'Password not valid.',
            DO_YOU_WANT_TO_LOGOUT: 'Do you want to logout?',
            ALL_CHANGES_WILL_BE_LOST_IF_YOU_LEAVE_THIS_POPUP_TAB_WITHOUT_SAVING: `All changes will be lost if you leave this popup tab without saving.`,
            PLEASE_ENTER_THE_NAME_OF_THE_INGREDIENT: "Please enter the name of the ingredient.",
            PLEASE_ENTER_THE_PRICE_OF_THE_INGREDIENT: "Please enter the price of the ingredient.",
            PLEASE_ENTER_THE_UNIT_OF_THE_INGREDIENT: "Please enter the unit of the ingredient.",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_NAME_THIS_WILL_AFFECT_ALL_THE_PROCESSED_AND_RECIPES_WITH_THIS_INGREDIENT: `Are you sure you want to delete %1$ ? This will affect all the processed and recipes with this ingredient!!!`,
            PLEASE_ENTER_THE_NAME_OF_THE_PROCESSED: "Please enter the name of the processed.",
            PLEASE_ENTER_THE_UNIT_OF_THE_PROCESSED: "Please enter the unit of the processed.",
            PLEASE_INSERT_ALMOST_A_INGREDIENT: "Please insert almost a ingredient.",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_NAME_THIS_WILL_AFFECT_ALL_THE_RECIPES_WITH_THIS_PROCESSED: `Are you sure you want to delete %1$ ? This will affect all the recipes with this processed!!!`,
            PLEASE_ENTER_A_VALID_EMAIL: "Please enter a valid email.",
            PASSWORD_TOO_SHORT: "Password too short.",
            CONFIRM_PASSWORD_DOESNT_MATCHES: "Confirm password doesn't matches.",
            THANK_YOU_FOR_YOUR_REGISTRATION: "Thank you for your registration."
        },
        calc: {
            calculator: 'Calculator',
            expenses: 'Expenses',
            taxes: 'Taxes',
            profit: 'Profit'
        },
        commons: {
            table: {
                name: 'Name',
                price: 'Price',
                unit: 'Unit',
                supplier: 'Supplier',
                note: 'Note',
                id: 'ID',
                order: 'Order',
                cooked: 'Cooked',
                cost: 'Cost',
                qt: 'Qt',
                ingredient: 'Ingredient',
                processed: 'Processed',
                noresults: 'No results',
                nothing: 'Nothing yet..',
            },
            containsAllergens: 'contains allergens',
            ingredients: 'Ingredients',
            processed: 'Processed',
            totalCost: 'Total cost',
            totalCostOfIngredients: 'Cost of ingredients',
            totalCostOfProcessed: 'Cost of processed',
            totalCostIngredientsProcessed: 'Cost ingredients / processed',
            totalCostCalc: 'Cost expenses / taxes / profit',
            totalCostOfProcessedAutoConverted: 'cost and quantity will be automatically converterted by unit when saving',
            exportCsv: 'Export to CSV',
            ok: 'Ok',
            save: 'Save',
            cancel: 'Cancel',
            new: 'New',
            edit: 'Edit',
            duplicate: 'Duplicate',
            delete: 'Delete',
            search: 'Search',
            newIngredient: 'New Ingredient',
            newProcessed: 'New Processed',
            newRecipe: 'New Recipe',
            addImage: 'Add Image',
            removeImage: 'Remove Image',
            addIngredients: 'Add Ingredients',
            addProcessed: 'Add Processed',
            editIngredient: 'Edit Ingredient',
            editProcessed: 'Edit Processed',
            optionalNote: 'Optional Note',
            requiredFields: 'required fields',
            unit: 'Unit',
            kilogram: 'kilogram',
            gram: 'gram',
            litre: 'litre'
        },
        allergens: {
            celery: 'celery',
            gluten: 'gluten',
            cereals: 'gluten',
            crustaceans: 'crustaceans',
            eggs: 'eggs',
            fish: 'fish',
            peanuts: 'peanuts',
            soy: 'soy',
            dairy: 'dairy',
            nuts: 'nuts',
            mustard: 'mustard',
            sesame: 'sesame',
            sulphites: 'sulphites',
            lupin: 'lupin',
            molluscs: 'molluscs',
            mushroom: 'mushroom'
        },
        cookies: {
            title: 'But first, cookies',
            text: 'We use cookies to improve your experience and deliver personalized content. By using Costopiatto, you agree to our %1$.',
            button: 'Got it'
        },
        header: {
            howItWorks: 'How it works',
            faq: 'FAQ',
            login: 'Log In',
            requestDemo: 'Request a demo',
            logout: 'Logout',
            ingredients: 'Ingredients',
            processed: 'Processed',
            recipes: 'Recipes',
            settings: 'Settings'
        },
        footer: {
            privacyPolicy: 'Privacy Policy',
            cookiePolicy: 'Cookie Policy',
            termsOfUse: 'Terms of Use'
        },
        privacyPolicy: {
            title: 'Privacy Policy'
        },
        cookiePolicy: {
            title: 'Cookie Policy'
        },
        termsOfUse: {
            title: 'Terms of Use'
        },
        ingredientsPage: {
            title: 'Ingredients'
        },
        processed: {
            title: 'Processed'
        },
        recipes: {
            title: 'Recipes'
        },
        recipe: {
            title: 'Recipe',
            new: 'New',
            edit: ''
        },
        settings: {
            title: 'Settings'
        },
        home: {
            hero: {
                title: 'Do you know the cost of the dishes you serve?',
                subtitle: 'There are loads of expenses for a restaurant to account, yet the accurate calculation of ingredient’s cost for each dish got ignored many times'
            },
            howItWorks: {
                title: 'How it works',
                main: '',
                array: [
                    {
                        ico: 'list',
                        title: 'Request a demo',
                        text: 'click on the button at the top-right of the page fill the form and ask for a demo'
                    },
                    {
                        ico: 'folk',
                        title: 'Add your recipe',
                        text: 'add the ingredients of the dish and their prices per unite to find instantly the cost of the dish'
                    },
                    {
                        ico: 'cross',
                        title: 'Database of Recipes',
                        text: 'Always accessible and any change, updates them across the platform'
                    }
                ]
            },
            try: {
                title: 'Try it for free',
                text: 'You\'ll have an unlimited access to the ingredients database and the recipes are accessible from mobile, tablet and computer.'
            },
            faq: {
                title: 'FAQ',
                array: [
                    {
                        question: 'What costopiatto does?',
                        answer: 'It calculates the cost of all ingredients used in the dish, by applying the unit prices existing in your own database of ingredients. The ingredients database and the recipes are also accessible and editable via your mobile phone. You can check and edit the prices and costs, add or remove ingredients and change whenever you want.'
                    },
                    {
                        question: 'Does it only calculate the cost ingredients?',
                        answer: 'No. To the net cost of your ingredients You can add the VAT, any other Expenses and your Profit.',

                    },
                    {
                        question: 'For whom is this service?',
                        answer: 'Generally it is for anybody who is in food business: Chefs, maître, food consultants, etc.',

                    },
                    {
                        question: 'What is the price?',
                        answer: 'Currently it is free. This is a beta version and we understand the service can be improved. :)'
                    }
                ]
            }
        },
        login: {
            title: 'Sign In',
            disclaimer: 'By joining Costopiatto you are agreeing to our %1$, %2$ and %3$.',
            email: 'Email',
            password: 'Password',
            submit: 'Continue',
            footer: 'Don\'t you have an account yet? %1$.'
        },
        demo: {
            im: [
                `I'm...`,
                `I'm a Chef`,
                `I'm a Commis`,
                `I'm a Maître`,
                `I'm a Food Consultant`,
                `I'm Other..`
            ],
            title: 'Request a demo, it\'s free',
            disclaimer: 'By joining Costopiatto you are agreeing to our %1$, %2$ and %3$.',
            name: 'Name',
            email: 'Email',
            message: 'Message',
            submit: 'Continue',
            footer: 'Already have an account? %1$.'
        },
        register: {
            title: 'Sign Up',
            disclaimer: 'By joining Costopiatto you are agreeing to our %1$, %2$ and %3$.',
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Confirm password',
            submit: 'Continue',
            footer: 'Already have an account? %1$.'
        }
    },
    it: {
        alerts: {
            RECIPE_SAVED: 'Ricetta Salvata!',
            RECIPE_UPDATED: 'Ricetta Aggiornata!',
            RECIPE_DELETED: 'Ricetta Cancellata!',
            INGREDIENT_SAVED: 'Ingrediente Salvato!',
            INGREDIENT_UPDATED: 'Ingrediente Aggiornato!',
            INGREDIENT_DELETED: 'Ingrediente Cancellato!',
            PROCESSED_SAVED: 'Elaborato Salvato!',
            PROCESSED_UPDATED: 'Elaborato Aggiornato!',
            PROCESSED_DELETED: 'Elaborato Cancellato!',
            PLEASE_ENTER_THE_NAME_OF_THE_RECIPE: "Inserire il nome della ricetta.",
            PLEASE_INSERT_ALMOST_A_INGREDIENT_OR_PROCESSED: "Inserire almeno un ingrediente o elaborato.",
            PLEASE_ENTER_A_VALID_QUANTITY_FOR_THE_INGREDIENTS: "Inserire una valida quantità per gli ingredienti.",
            PLEASE_ENTER_A_VALID_QUANTITY_FOR_THE_PROCESSED: "Inserire una valida quantità per gli elaborati.",
            ARE_YOU_SURE_YOU_WANT_TO_REMOVE_NAME: `Confermi la rimozione di %1$ ?`,
            ARE_YOU_SURE_YOU_WANT_TO_DUPLICATE_THIS_RECEIPE: `Confermi di voler duplicare questa ricetta ?`,
            ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_RECEIPE: `Confermi la rimozione di questa ricetta ?`,
            THANK_YOU_FOR_YOUR_INTEREST_WE_WILL_REPLY_YOU_SOON: "Grazie, ti risponderemo in tempi brevi.",
            PLEASE_ENTER_ALL_FIELDS: "Completare tutti i campi.",
            INVALID_CREDENTIALS: "Credenziali non valide.",
            EMAIL_NOT_VALID: 'Email non valida.',
            PASSWORD_NOT_VALID: 'Password non valida.',
            DO_YOU_WANT_TO_LOGOUT: 'Confermi il logout?',
            ALL_CHANGES_WILL_BE_LOST_IF_YOU_LEAVE_THIS_POPUP_TAB_WITHOUT_SAVING: `Tutte le modifiche non salvate saranno perse.`,
            PLEASE_ENTER_THE_NAME_OF_THE_INGREDIENT: "Inserire il nome dell'ingrediente.",
            PLEASE_ENTER_THE_PRICE_OF_THE_INGREDIENT: "Inserire il prezzo dell'ingrediente.",
            PLEASE_ENTER_THE_UNIT_OF_THE_INGREDIENT: "Selezionare l'unità di misura dell'ingrediente.",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_NAME_THIS_WILL_AFFECT_ALL_THE_PROCESSED_AND_RECIPES_WITH_THIS_INGREDIENT: `Confermi la rimozione di %1$ ? Questa modifica è irreversibile e modificherà tutte le ricette ed elaborati con questo ingrendiente!!!`,
            PLEASE_ENTER_THE_NAME_OF_THE_PROCESSED: "Inserire il nome dell'elaborato.",
            PLEASE_ENTER_THE_UNIT_OF_THE_PROCESSED: "Selezionare l'unità di misura dell'elaborato.",
            PLEASE_INSERT_ALMOST_A_INGREDIENT: "Inserire almeno un ingrediente.",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_NAME_THIS_WILL_AFFECT_ALL_THE_RECIPES_WITH_THIS_PROCESSED: `Confermi la rimozione di %1$ ? Questa modifica è irreversibile e modificherà tutte le ricette con questo elaborato!!!`,
            PLEASE_ENTER_A_VALID_EMAIL: "Inserire una valida email.",
            PASSWORD_TOO_SHORT: "Password troppo corta.",
            CONFIRM_PASSWORD_DOESNT_MATCHES: "Password diversa da conferma password.",
            THANK_YOU_FOR_YOUR_REGISTRATION: "Grazie per la tua registrazione."
        },
        calc: {
            calculator: 'Calcolatrice',
            expenses: 'Spese',
            taxes: 'Tasse',
            profit: 'Profitto'
        },
        commons: {
            table: {
                name: 'Nome',
                price: 'Prezzo',
                unit: 'Unità',
                supplier: 'Venditore',
                note: 'Note',
                id: 'ID',
                order: 'Ordine',
                cooked: 'Cucinato',
                cost: 'Costo',
                qt: 'Qt',
                ingredient: 'Ingrediente',
                processed: 'Elaborato',
                noresults: 'Nessun risultato',
                nothing: 'Ancora niente..',
            },
            containsAllergens: 'contiene allergeni',
            ingredients: 'Ingredienti',
            processed: 'Elaborati',
            totalCost: 'Costo totale',
            totalCostOfIngredients: 'Costo degli ingredienti',
            totalCostOfProcessed: 'Costo degli elaborati',
            totalCostIngredientsProcessed: 'Costo ingredienti / elaborati',
            totalCostCalc: 'Costo spese / tasse / profitto',
            totalCostOfProcessedAutoConverted: 'costo totale e quantità saranno automaticamente convertiti in unità durante il salvataggio',
            exportCsv: 'Esporta in CSV',
            save: 'Salva',
            ok: 'Ok',
            cancel: 'Annulla',
            new: 'Nuovo',
            edit: 'Edita',
            duplicate: 'Duplica',
            delete: 'Elimina',
            search: 'Cerca',
            newIngredient: 'Nuovo Ingrediente',
            newProcessed: 'Nuovo Elaborato',
            newRecipe: 'Nuova Ricetta',
            addImage: 'Aggiungi',
            removeImage: 'Rimuovi',
            addIngredients: 'Aggiungi Ingredienti',
            addProcessed: 'Aggiungi Elaborati',
            editIngredient: 'Edita Ingrediente',
            editProcessed: 'Edita Elaborato',
            optionalNote: 'Note Opzionali',
            requiredFields: 'campi richiesti',
            unit: 'Unità',
            kilogram: 'kilogrammo',
            gram: 'grammo',
            litre: 'litro'
        },
        allergens: {
            celery: 'sedano',
            gluten: 'glutine',
            cereals: 'glutine',
            crustaceans: 'crostacei',
            eggs: 'uova',
            fish: 'pesce',
            peanuts: 'arachidi',
            soy: 'soia',
            dairy: 'latticini',
            nuts: 'frutta a guscio',
            mustard: 'mostarda',
            sesame: 'sesamo',
            sulphites: 'solfiti',
            lupin: 'fave',
            molluscs: 'molluschi',
            mushroom: 'funghi'
        },
        cookies: {
            title: 'Biscottini..',
            text: 'Utilizziamo i cookie al fine di migliorare l\'esperienza utente con contenuti personalizzati. Utilizzando Costopiatto, accetti la nostra %1$.',
            button: 'Ho capito'
        },
        header: {
            howItWorks: 'Come funziona',
            faq: 'FAQ',
            login: 'Accedi',
            requestDemo: 'Richiedi una demo',
            logout: 'Disconnetti',
            ingredients: 'Ingredienti',
            processed: 'Elaborati',
            recipes: 'Ricette',
            settings: 'Impostazioni'
        },
        footer: {
            privacyPolicy: 'Privacy Policy',
            cookiePolicy: 'Cookie Policy',
            termsOfUse: 'Termini di Utilizzo'
        },
        privacyPolicy: {
            title: 'Privacy Policy'
        },
        cookiePolicy: {
            title: 'Cookie Policy'
        },
        termsOfUse: {
            title: 'Termini di Utilizzo'
        },
        ingredientsPage: {
            title: 'Ingredienti'
        },
        processed: {
            title: 'Elaborati'
        },
        recipes: {
            title: 'Ricette'
        },
        recipe: {
            title: 'Ricetta',
            new: 'Nuova',
            edit: ''
        },
        settings: {
            title: 'Impostazioni'
        },
        home: {
            hero: {
                title: 'Conosci il costo dei tuoi piatti?',
                subtitle: 'Ci sono molte spese per un ristorante, ma il calcolo accurato del costo degli ingredienti per ogni piatto è spesso ignorato'
            },
            howItWorks: {
                title: 'Come funziona',
                main: '',
                array: [
                    {
                        ico: 'list',
                        title: 'Richiedi una demo',
                        text: 'Clicca sul bottone in alto a destra della pagina compila il form e richiedi una demo'
                    },
                    {
                        ico: 'folk',
                        title: 'Aggiungi la tua ricetta',
                        text: 'aggiungere gli ingredienti del piatto e il loro prezzo unitario per trovare subito il costo del piatto'
                    },
                    {
                        ico: 'cross',
                        title: 'DB delle ricette',
                        text: 'Sempre accessibile e qualsiasi modifica, li aggiorna su tutta la piattaforma'
                    }
                ]
            },
            try: {
                title: 'Provalo, è gratuito..',
                text: 'Avrai accesso illimitato al database degli ingredienti e le ricette sono accessibili da cellulare, tablet e computer.'
            },
            faq: {
                title: 'FAQ',
                array: [
                    {
                        question: 'Cosa fa il costopiatto?',
                        answer: 'Calcola il costo di tutti gli ingredienti utilizzati nel piatto, applicando i prezzi per unità degli ingredienti che aggiungi. Il database degli ingredienti e le ricette sono accessibili e modificabili anche tramite il tuo cellulare. Puoi verificare e modificare i prezzi e i costi, aggiungere o rimuovere ingredienti e modificarli quando vuoi.'
                    },
                    {
                        question: 'Calcola solo il costo degli ingredienti?',
                        answer: 'No. Al costo netto dei tuoi ingredienti puoi aggiungere IVA, eventuali altre spese e il profitto'
                    },
                    {
                        question: 'A chi serve questo servizio?',
                        answer: 'In generale, è utile per chi lavora nel settore di restorazione: chef, maître, consulenti alimentari, etc.',

                    },
                    {
                        question: 'Quanto costa?',
                        answer: 'Attualmente è gratuito. Questa è una versione beta e ci rendiamo conto che il servizio può essere migliorato. :)'
                    }
                ]
            }
        },
        login: {
            title: 'Accedi',
            disclaimer: 'Aderendo a Costopiatto stai accettando la nostra %1$, %2$ e %3$.',
            email: 'Email',
            password: 'Password',
            submit: 'Continua',
            footer: 'Non hai un account? %1$.'
        },
        demo: {
            im: [
                `Sono...`,
                `Sono uno Chef`,
                `Sono un Commis`,
                `Sono un Maître`,
                `Sono un Consulente Gastronomico`,
                `Sono Altro..`
            ],
            title: 'Richiedi una demo, è gratuita',
            disclaimer: 'Aderendo a Costopiatto stai accettando la nostra %1$, %2$ e %3$.',
            name: 'Nome',
            email: 'Email',
            message: 'Messaggio',
            submit: 'Continua',
            footer: 'Hai già un account? %1$.'
        },
        register: {
            title: 'Registrati',
            disclaimer: 'Aderendo a Costopiatto stai accettando la nostra %1$, %2$ e %3$.',
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Conferma password',
            submit: 'Continua',
            footer: 'Hai già un account? %1$.'
        }
    }
}

// lang.en.ingredients = ingredientsEn;
// lang.it.ingredients = ingredientsIt;

function t(selectedLang, obj) {
    const value = !checkNested(lang, selectedLang, ...obj.split('.')) ? checkNested(lang, defaultLang, ...obj.split('.')) : checkNested(lang, selectedLang, ...obj.split('.'));
    return value ? value : '';
}

const list = [
    {
        label: 'English',
        val: 'en'
    },
    {
        label: 'Italiano',
        val: 'it'
    }
]

export { list, lang, t };