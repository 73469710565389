import React, { useContext } from 'react';
import { Link } from "react-router-dom"
import { t, list } from '../lang';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

function Page(props) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const lang = appState.lang;

    return (
        <div className="footer">
            <ul>
                <li><Link to="/privacy-policy">{t(lang, 'footer.privacyPolicy')}</Link></li>
                <li><Link to="/cookie-policy">{t(lang, 'footer.cookiePolicy')}</Link></li>
                <li><Link to="/terms-of-use">{t(lang, 'footer.termsOfUse')}</Link></li>
                <li><select value={lang} onChange={(e) => appDispatch({ type: 'setLang', data: e.target.value })}>
                    {
                        list.map((el, key) => <option key={key} value={el.val}>{el.label}</option>)
                    }
                </select></li>
            </ul>
            <p>&copy; Costopiatto {new Date().getFullYear()}</p>
        </div>
    );
}

export default Page;
