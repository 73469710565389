// const isLive = window.location.hostname !== 'localhost';

const config = {
    GA: 'G-YPP0DC4GM7',
    supportBase64value: atob('c3VwcG9ydEBjb3N0b3BpYXR0by5jb20='), // support@costopiatto.com
    api: 'https://api.costopiatto.com/api/v1',
    // api: isLive ? 'https://api.costopiatto.com/api/v1' : 'http://localhost:8080/api/v1',
    // assets: 'https://costopiatto.s3.eu-central-1.amazonaws.com/',
    // assets: 'https://costopiatto-assets.s3.eu-central-1.amazonaws.com/',
    assets: 'https://api.costopiatto.com/assets/',
    static: '',
    // static: isLive ? '/costopiatto' : '/costopiatto',
    units: [
        { label: 'kilogram', value: 'kg' },
        // { label: 'gram', value: 'g' },
        { label: 'litre', value: 'l' }
    ],
    currencies: {
        eur: '€',
        gbp: '£',
        usd: '$'
    }
}

export default config;