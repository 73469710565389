import _ from "lodash";

let selectedImages = [];

function randomImage(array) {

    const images = array ? array : ['001', '002', '003', '004', '005', '006', '007', '008', '009', '010'];
    let selectedImage = _.shuffle(images)[0];

    if (selectedImages.length === images.length) return selectedImage;

    if (selectedImages.find(el => el === selectedImage)) return randomImage();

    selectedImages.push(selectedImage);

    return selectedImage;

}

export default randomImage;