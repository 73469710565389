import React from 'react';

export default function sprintfReact(originalString, array) {

    let stringSteps = originalString.split(' ');
    let newObj = [];
    let counter = 0;

    stringSteps.forEach((el, index) => {
        if (el.includes(`%${counter + 1}$`)) {
            const difference = el.split(`%${counter + 1}$`).join('');
            newObj.push(<React.Fragment key={index}>{array[counter]}</React.Fragment>);
            if (difference) newObj.push(<React.Fragment key={index + 'd'}>{difference}</React.Fragment>);
            counter++;
        }
        else newObj.push(<React.Fragment key={index}>{el}</React.Fragment>);
        newObj.push(<React.Fragment key={index + 's'}>{' '}</React.Fragment>);
    });

    return (<>{newObj}</>)

}
