import http from "./httpService";
import qs from 'querystring'
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function send({ im, email, name, message, request }) {
    return await http({
        method: 'post',
        url: `${config.api}/request-demo`,
        data: qs.stringify({ im, email, name, message }),
        cancelToken: request.token
    });
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    send,
    cancelToken
};
