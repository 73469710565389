import checkNested from '../utils/checkNested';

export default function defaultError(e) {

    let response = false;

    const customError = checkNested(e, 'response', 'data', 'errors');
    if (customError) response = Array.isArray(customError) ? customError.map(el => el).join(', ') : customError;

    if (!response) response = checkNested(e, 'err', 'message') ? e.err.message : false;
    if (!response) response = checkNested(e, 'message') ? e.message : false;
    if ((typeof response) !== 'string') response = JSON.stringify(e);

    return response;

}