import React, { useContext } from 'react';
import { Link, useLocation } from "react-router-dom"
import { t } from '../lang';
import ImageFadeIn from "react-image-fade-in";

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

function Page(props) {

    const location = useLocation();

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const user = appState.user;

    const lang = appState.lang;

    function confirmLogout() {

        appDispatch({
            type: "alertOpen",
            value: t(lang, 'alerts.DO_YOU_WANT_TO_LOGOUT'),
            callback: () => {
                appDispatch({ type: 'setLogout' });
                // appDispatch({ type: 'setToken', data: false });
            }
        });

    }

    return (
        <ul>
            {appState.loggedIn && <li class={location.pathname === '/recipes' || location.pathname === '/' ? 'active' : null}> <Link onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to="/recipes">{t(lang, 'header.recipes')}</Link></li>}
            {appState.loggedIn && <li class={location.pathname === '/processed' ? 'active' : null}> <Link onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to="/processed">{t(lang, 'header.processed')}</Link></ li>}
            {appState.loggedIn && <li class={location.pathname === '/ingredients' ? 'active' : null}><Link onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to="/ingredients">{t(lang, 'header.ingredients')}</Link></li>}
            {
                appState.loggedIn && <li><Link className="avatar" onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to="/settings"><span>{t(lang, 'header.settings')}</span>
                    {user && user.avatar && <ImageFadeIn src={`${user.avatar}?s=128&d=https://costopiatto.com/assets/gravatar/gravatar.png`} />}
                </Link></li>
            }
            {appState.loggedIn && <li><button onClick={(e) => { appDispatch({ type: 'hideNav' }); confirmLogout() }} className="btn logout">{t(lang, 'header.logout')}</button></li>}
            {!appState.loggedIn && <li><Link onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to="/#how-it-works">{t(lang, 'header.howItWorks')}</Link></li>}
            {!appState.loggedIn && <li><Link onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to="/#faq">{t(lang, 'header.faq')}</Link></li>}
            {!appState.loggedIn && <li><button onClick={(e) => { appDispatch({ type: 'hideNav' }); appDispatch({ type: 'toggleShowLogin' }) }} className="btn login">{t(lang, 'header.login')}</button></li>}
            {!appState.loggedIn && <li><button onClick={(e) => { appDispatch({ type: 'hideNav' }); appDispatch({ type: 'toggleShowDemo' }) }} className="btn register">{t(lang, 'header.requestDemo')}</button></li>}
        </ul >
    );
}

export default Page;
