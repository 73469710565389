import React, { useContext } from 'react';
import { Link } from "react-router-dom"

// context
// import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

import Nav from "./Nav";

function Page(props) {

    // const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    return (
        <div className="header">
            <div className="logo"><Link to="/"></Link></div>
            <button onClick={(e) => { appDispatch({ type: 'toggleShowNav' }) }} className="navigation-button"></button>
            <Nav />
        </div>
    );
}

export default Page;
