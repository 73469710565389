const storageLabel = 'costopiatto';

export function set(name, value) {

	const cache = get();
	const newCache = { ...cache, ...{ [name]: value } }

	localStorage.setItem(storageLabel, JSON.stringify(newCache));

};

export function get(name) {

	const cache = JSON.parse(localStorage.getItem(storageLabel));
	return !name ? cache : (cache && cache[name]) ? cache[name] : null;

}

export function del(name) {

	const cache = get();
	let newCache = null;
	if (cache[name]) {
		delete cache[name];
		newCache = { ...cache };
	}

	localStorage.setItem(storageLabel, JSON.stringify(newCache));

}

// eslint-disable-next-line 
export default {
	set,
	get,
	del
};
