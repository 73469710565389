import React, { useContext, useState, useEffect } from 'react';

// context
import DispatchContext from "../context/DispatchContext";

function Secret(props) {

    const appDispatch = useContext(DispatchContext);

    const [secret, setSecret] = useState(0);

    function handleSecret() {

        setSecret(secret + 1);

    }

    useEffect(() => {

        if (secret >= 10) {

            appDispatch({ type: "toggleShowRegister" });
            appDispatch({ type: "hideLogin" });
            appDispatch({ type: "hideDemo" });

        }

        // eslint-disable-next-line
    }, [secret]);

    return (
        <div className="isro" onClick={(e) => handleSecret()}></div>
    );
}

export default Secret;
