import React from 'react';

function Loading() {
    return (
        <div className="section section--loading">
            <div className="dots-loading">
                <div></div>
            </div>
        </div>
    );
}

export default Loading;