import React, { useContext } from 'react';
import { Link } from "react-router-dom"
import sprintfReact from '../utils/sprintfReact';
import { t } from '../lang';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

function Cookies(props) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const lang = appState.lang;

    function handleCookie() {

        appDispatch({ type: 'setCookie' });

    }

    return (
        <>
            <h4>{t(lang, 'cookies.title')}</h4>
            <p>
                {
                    sprintfReact(
                        t(lang, 'cookies.text'),
                        [
                            <Link onClick={(e) => { appDispatch({ type: 'hideNav' }); }} to={"cookie-policy"}>{t(lang, 'footer.cookiePolicy')}</Link>
                        ]
                    )
                }
            </p>
            <div onClick={(e) => { handleCookie(); }} className="btn">{t(lang, 'cookies.button')}</div>
        </>
    );
}

export default Cookies;


