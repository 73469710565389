import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import ImageFadeIn from "react-image-fade-in";
import _ from "lodash";
import { t } from '../lang';
import sprintfReact from '../utils/sprintfReact';
import defaultError from '../utils/defaultError';
import config from '../config';
// import sleep from '../utils/sleep';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import auth from "../services/authService";

const images = ['001', '002'];
const image = _.shuffle(images)[0];

function Register() {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [keycode, setKeycode] = useState('');
    const [showSecret, setShowSecret] = useState('');

    const [currency, setCurrency] = useState('eur');
    const [language, setLanguage] = useState('it');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [isLoading, setIsLoading] = useState('');

    useEffect(() => {

        if (keycode === 'CiaoCosto') {

            setShowSecret(true);

        }

    }, [keycode])

    const lang = appState.lang;

    const handleClose = useCallback(() => {

        appDispatch({ type: 'toggleShowRegister' });

        // eslint-disable-next-line
    }, [])

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    async function handleSubmit(e) {

        e.preventDefault();

        const request = auth.cancelToken();

        if (!validateEmail(email)) {

            return appDispatch({
                type: "alertOpen",
                ico: "error",
                value: t(lang, 'alerts.PLEASE_ENTER_A_VALID_EMAIL')
            });

        }

        if (password.length < 8) {

            return appDispatch({
                type: "alertOpen",
                ico: "mail",
                value: t(lang, 'alerts.PASSWORD_TOO_SHORT')
            });

        }

        if (password !== confirmPassword) {

            return appDispatch({
                type: "alertOpen",
                ico: "mail",
                value: t(lang, 'alerts.CONFIRM_PASSWORD_DOESNT_MATCHES')
            });

        }

        setIsLoading(true);

        // await sleep(500);

        try {

            await auth.register({ email, password, currency, language, request });

            appDispatch({ type: "toggleShowRegister" });
            setIsLoading(false);

            appDispatch({
                type: "alertOpen",
                ico: "mail",
                value: t(lang, 'alerts.THANK_YOU_FOR_YOUR_REGISTRATION')
            });

        } catch (e) {

            if (e && e.isCancel !== true) {

                appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: defaultError(e)
                });

                setIsLoading(false);

            }

        }

        return () => { request.cancel(); }

    }

    useEffect(() => {

        function searchKeyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        document.addEventListener("keyup", searchKeyPressHandler)
        return () => document.removeEventListener("keyup", searchKeyPressHandler)

    }, [handleClose]);

    return (
        <>
            <button onClick={(e) => handleClose()} className="btn btn--close">.</button>
            <div className="col col--bg">
                <div className="box">
                    <div className="logo"></div>
                    <h3>{t(lang, 'register.title')}</h3>
                    <p>{
                        sprintfReact(
                            t(lang, 'register.disclaimer'),
                            [
                                <Link onClick={(e) => handleClose()} to={"privacy-policy"}>{t(lang, 'footer.privacyPolicy')}</Link>,
                                <Link onClick={(e) => handleClose()} to={"cookie-policy"}>{t(lang, 'footer.cookiePolicy')}</Link>,
                                <Link onClick={(e) => handleClose()} to={"terms-of-use"}>{t(lang, 'footer.termsOfUse')}</Link>
                            ]
                        )
                    }</p>
                </div>
                <div className="bg">
                    <ImageFadeIn src={`${process.env.PUBLIC_URL}/assets/img/login/${image}.jpg`} />
                </div>
            </div>
            <div className="col">
                <div className="box">
                    <form onSubmit={handleSubmit}>

                        {!showSecret && <div className="input-container"><input onChange={(e) => setKeycode(e.target.value)} type="text" placeholder="Keyword" value={keycode} /></div>}

                        {showSecret &&
                            <>
                                <div className="input-container"><input onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t(lang, 'register.email')} value={email} /></div>
                                <div className="input-container"><input onChange={(e) => setPassword(e.target.value)} type="password" placeholder={t(lang, 'register.password')} value={password} /></div>
                                <div className="input-container"><input onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder={t(lang, 'register.confirmPassword')} value={confirmPassword} /></div>
                                <div className="input-container">
                                    <select onChange={(e) => setLanguage(e.target.value)}>
                                        <option value="it">Italiano</option>
                                        <option value="en">English</option>
                                    </select>
                                </div>
                                <div className="input-container">
                                    <select onChange={(e) => setCurrency(e.target.value)}>
                                        {Object.keys(config.currencies).map((key, index) => <option key={index} value={key}>{config.currencies[key]}</option>)}
                                    </select>
                                </div>
                                <button disabled={isLoading} className="btn" type="submit">
                                    <span className="loading"></span>
                                    <span>{t(lang, 'register.submit')}</span>
                                </button>
                            </>
                        }
                    </form>
                    {/* <p>{
                        sprintfReact(
                            t(lang, 'register.footer'),
                            [
                                <button onClick={(e) => { appDispatch({ type: 'toggleShowLogin' }); appDispatch({ type: 'toggleShowRegister' }) }}>{t(lang, 'header.login')}</button>
                            ]
                        )
                    }</p> */}
                </div>
            </div>
        </>
    );
}

export default Register;
