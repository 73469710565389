import React, { Suspense, useRef, useEffect } from 'react';

import { useImmerReducer } from "use-immer";
// import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion'
import { CSSTransition } from "react-transition-group";
// import config from './config';
import { t } from './lang';

// utils
import storage from './utils/storage'

// import NotFound from './components/NotFound';
import Cookies from './components/Cookies';
import NavMobile from './components/NavMobile';
import AlertReact from './components/AlertReact';
import EmptyLoadingPage from './components/EmptyLoadingPage';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Demo from './components/Demo';

// import PrivacyPolicy from './components/PrivacyPolicy';
// import CookiePolicy from './components/CookiePolicy';
// import TermsOfUse from './components/TermsOfUse';

import Header from './components/Header';
import Footer from './components/Footer';

import './assets/scss/style.scss';

// context
import StateContext from "./context/StateContext";
import DispatchContext from "./context/DispatchContext";

import usePageTracking from './utils/usePageTracking';

const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const CookiePolicy = React.lazy(() => import("./components/CookiePolicy"));
const TermsOfUse = React.lazy(() => import("./components/TermsOfUse"));

const Admin = React.lazy(() => import("./components/Admin"));

function App() {

  usePageTracking();

  const alertNodeRef = useRef(null);

  const initialState = {
    loggedIn: Boolean(storage.get('token')),
    lang: storage.get('lang') ? storage.get('lang') : navigator.language.toLowerCase().substring(0, 2),
    token: storage.get('token'),
    user: storage.get('user'),
    cookie: storage.get('cookie'),
    alert: {
      show: false,
      text: '',
      ico: '',
      callback: false
    },
    showNav: false,
    showLogin: false,
    showRegister: false,
    showDemo: false,
    logoutCounter: 0,
    redirectTrigger: false
  }

  function reducer(draft: any, action: any) {
    switch (action.type) {
      case "setLang":
        storage.set('lang', action.data);
        draft.lang = action.data;
        return
      case "setLogout":
        draft.logoutCounter = draft.logoutCounter + 1;
        return
      case "setRedirectTrigger":
        draft.redirectTrigger = action.data;
        return

      case "hideLogin": draft.showLogin = false; document.title = `Costopiatto`; return
      case "hideRegister": draft.showRegister = false; document.title = `Costopiatto`; return
      case "hideDemo": draft.showDemo = false; document.title = `Costopiatto`; return

      case "hideNav":
        draft.showNav = false;
        return
      case "toggleShowNav":
        const newStateNav = !draft.showNav;
        draft.showNav = newStateNav;
        return
      case "toggleShowLogin":
        draft.showLogin = !draft.showLogin;

        if (draft.showLogin) document.title = `${t(draft.lang, 'login.title')} | Costopiatto`;
        else document.title = `Costopiatto`;

        return
      case "toggleShowRegister":
        draft.showRegister = !draft.showRegister;

        if (draft.showRegister) document.title = `${t(draft.lang, 'register.title')} | Costopiatto`;
        else document.title = `Costopiatto`;

        return
      case "toggleShowDemo":
        draft.showDemo = !draft.showDemo;

        if (draft.showDemo) document.title = `${t(draft.lang, 'demo.title')} | Costopiatto`;
        else document.title = `Costopiatto`;

        return



      case "alertClose":
        draft.alert.show = false;
        return
      case "alertOpen":
        draft.alert.show = true;
        draft.alert.ico = action.ico;
        draft.alert.text = action.value;
        draft.alert.callback = action.callback;
        return
      case "setCookie":
        storage.set('cookie', true);
        draft.cookie = true;
        return
      case "setToken":
        storage.set('token', action.data);
        draft.token = action.data;
        draft.loggedIn = action.data;
        return
      case "setUser":
        storage.set('user', action.data);
        draft.user = action.data;
        return
    }
  }

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  useEffect(() => {

    if (state.logoutCounter) {

      dispatch({ type: 'setToken', data: false });
      dispatch({ type: 'setUser', data: false });
      dispatch({ type: 'setRedirectTrigger', data: true });

      storage.set('ingredients-it', false);
      storage.set('ingredients-en', false);
      storage.set('ingredientsImages', false);

    }

    // eslint-disable-next-line
  }, [state.logoutCounter]);

  useEffect(() => {

    if (state.redirectTrigger) {

      setTimeout(() => { dispatch({ type: 'setRedirectTrigger', data: false }); }, 300);

    }

    // eslint-disable-next-line
  }, [state.redirectTrigger,]);


  useEffect(() => {

    function loading() {
      setTimeout(function () {
        document.querySelector('html')!.classList.add('loaded');
        window.scrollTo(0, 0);
      }, 300);
    }
    window.addEventListener('load', loading);

  }, []);

  return (

    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>

        {/* <BrowserRouter basename={'/costopiatto'}> */}
        {/* <BrowserRouter basename={'/'}> */}

        {state.redirectTrigger && <Redirect to={'/'} />}

        <CSSTransition
          in={state.alert.show}
          classNames="alert"
          nodeRef={alertNodeRef}
          timeout={330}
          unmountOnExit
        >
          <div ref={alertNodeRef}>
            <AlertReact />
          </div>
        </CSSTransition>

        <AnimatePresence>
          {!state.cookie &&
            <motion.div
              initial={{ opacity: 0, y: '80px', x: '-50%' }}
              animate={{ opacity: 1, y: '0', x: '-50%' }}
              exit={{ opacity: 0, y: '80px', x: '-50%' }}
              transition={{ type: 'tween', duration: .3 }}
              className="cookies"
              id="cookies"
            >
              <Cookies />
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          {state.showNav &&
            <motion.div
              initial={{ opacity: 0, scale: 1.05 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.05 }}
              transition={{ type: 'tween', duration: 0.3 }}
              className="popup-fs popup-fs--nav"
            >
              <NavMobile />
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          {state.showLogin &&
            <motion.div
              initial={{ opacity: 0, scale: 1.05 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.05 }}
              transition={{ type: 'tween', duration: 0.3 }}
              className="popup-fs popup-fs--login"
            >
              <Login />
            </motion.div>
          }
          {
            state.showRegister &&
            <motion.div
              initial={{ opacity: 0, scale: 1.05 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.05 }}
              transition={{ type: 'tween', duration: 0.3 }}
              className="popup-fs popup-fs--register"
            >
              <Register />
            </motion.div>
          }
          {
            state.showDemo &&
            <motion.div
              initial={{ opacity: 0, scale: 1.05 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.05 }}
              transition={{ type: 'tween', duration: 0.3 }}
              className="popup-fs popup-fs--demo"
            >
              <Demo />
            </motion.div>
          }
        </AnimatePresence>

        <Header />

        <Suspense fallback={<EmptyLoadingPage />}>

          <Switch>

            <Route path="/privacy-policy"><PrivacyPolicy /></Route>
            <Route path="/cookie-policy"><CookiePolicy /></Route>
            <Route path="/terms-of-use"><TermsOfUse /></Route>
            <Route path="/test"><EmptyLoadingPage /></Route>

            {state.loggedIn && <Route path="/"><Admin /></Route>}

            <Route path="/"><Home /></Route>

          </Switch>

        </Suspense>

        <Footer />

        {/* </BrowserRouter> */}

      </DispatchContext.Provider>
    </StateContext.Provider >

  );
}

export default App;
