import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import ImageFadeIn from "react-image-fade-in";
import _ from "lodash";
import { t } from '../lang';
import sprintfReact from '../utils/sprintfReact';
import defaultError from '../utils/defaultError';
// import sleep from '../utils/sleep';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

import Secret from './Secret';

// services
import requestDemo from "../services/requestDemoService";

const images = ['001', '002'];
const image = _.shuffle(images)[0];

function Demo() {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const lang = appState.lang;

    const [im, setIm] = useState(t(lang, 'demo.im')[0]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState('');

    const handleClose = useCallback(() => {

        appDispatch({ type: 'toggleShowDemo' });

        // eslint-disable-next-line
    }, [])

    async function handleSubmit(e) {

        e.preventDefault();

        const request = requestDemo.cancelToken();

        if (
            name.length > 2 &&
            email.length > 2 &&
            message.length > 2
        ) {

            setIsLoading(true);

            // await sleep(500);

            try {

                const response = await requestDemo.send({ im, email, name, message, request });
                console.log(response);

                appDispatch({ type: "toggleShowDemo" });
                setIsLoading(false);

                appDispatch({
                    type: "alertOpen",
                    ico: "mail",
                    value: t(lang, 'alerts.THANK_YOU_FOR_YOUR_INTEREST_WE_WILL_REPLY_YOU_SOON'),
                });

            } catch (e) {

                if (e && e.isCancel !== true) {

                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultError(e)
                    });

                    setIsLoading(false);

                }

            }

        } else {

            appDispatch({
                type: "alertOpen",
                ico: "error",
                value: t(lang, 'alerts.PLEASE_ENTER_ALL_FIELDS'),
            });

        }

        return () => { request.cancel(); }

    }

    useEffect(() => {

        function searchKeyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        document.addEventListener("keyup", searchKeyPressHandler)
        return () => document.removeEventListener("keyup", searchKeyPressHandler)

    }, [handleClose]);

    return (
        <>
            <button onClick={(e) => handleClose()} className="btn btn--close">.</button>
            <div className="col col--bg">
                <div className="box">
                    <div className="logo"></div>
                    <h3>{t(lang, 'demo.title')}</h3>
                    <p>{
                        sprintfReact(
                            t(lang, 'demo.disclaimer'),
                            [
                                <Link onClick={(e) => handleClose()} to={"privacy-policy"}>{t(lang, 'footer.privacyPolicy')}</Link>,
                                <Link onClick={(e) => handleClose()} to={"cookie-policy"}>{t(lang, 'footer.cookiePolicy')}</Link>,
                                <Link onClick={(e) => handleClose()} to={"terms-of-use"}>{t(lang, 'footer.termsOfUse')}</Link>
                            ]
                        )
                    }</p>
                </div>
                <div className="bg">
                    <Secret />
                    <ImageFadeIn src={`${process.env.PUBLIC_URL}/assets/img/login/${image}.jpg`} />
                </div>
            </div>
            <div className="col">
                <div className="box">
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <select onChange={(e) => setIm(e.target.value)}>
                                {t(lang, 'demo.im').map((el, index) => <option key={index}>{el}</option>)}
                            </select>
                        </div>
                        <div className="input-container"><input onChange={(e) => setName(e.target.value)} type="text" placeholder={t(lang, 'demo.name')} value={name} /></div>
                        <div className="input-container"><input onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t(lang, 'demo.email')} value={email} /></div>
                        <div className="input-container"><textarea onChange={(e) => setMessage(e.target.value)} placeholder={t(lang, 'demo.message')} value={message} /></div>
                        <button disabled={isLoading} className="btn" type="submit">
                            <span className="loading"></span>
                            <span>{t(lang, 'demo.submit')}</span>
                        </button>
                    </form>
                    <p>{
                        sprintfReact(
                            t(lang, 'demo.footer'),
                            [
                                <button onClick={(e) => { appDispatch({ type: 'toggleShowLogin' }); appDispatch({ type: 'toggleShowDemo' }) }}>{t(lang, 'header.login')}</button>
                            ]
                        )
                    }</p>
                </div>
            </div>
        </>
    );
}

export default Demo;
